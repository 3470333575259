import api from 'o365.modules.data.api.js';
import { ref } from 'vue';

var modal = null;
const modalTemplate = `
    <div class="modal fade" id="o365-developer-menu-modal" tabindex="-1" aria-hidden="true">
        <div class="modal-dialog modal-lg">
            <div class="modal-content">
                <div class="modal-header">
                    <h5>Developer Menu</h5>
                    <div class="ms-auto">
                        <div class="form-check form-switch form-check-inline">
                            <input class="form-check-input" type="checkbox" id="o365-development-menu-hmr-switch">
                            <label class="form-check-label" for="o365-development-menu-hmr-switch">Enable HMR</label>
                        </div>
                        <div class="form-check form-switch form-check-inline">
                            <input class="form-check-input" type="checkbox" id="o365-development-menu-dark-mode-switch">
                            <label class="form-check-label" for="o365-development-menu-dark-mode-switch">Dark mode</label>
                        </div>
                    </div>
                    <button type="button" class="btn-close ms-2" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div class="modal-body row">

                </div>
            </div>
        </div>
    </div>
`;

const linkGroupTemplate = (title) => `
    <div class="col-12 col-sm6 col-lg-4">
        <h5 class="pb-1 pt-2 mb-0 border-bottom">${title}</h5>
    </div>
`;

const linkTemplate = (title, url) => `
    <a class="py-1 d-block text-decoration-none" href="${url}">${title}</a>
`;

const ODeveloperMenu =  {
    name: 'ODeveloperMenu',
    namespace: 'Components',
    setup() {
        const o365 = window.o365;
        const darkMode = ref(localStorage.getItem('devtools.mode') === 'true');

        function _setMonaco(isDarkMode) {
            if ('monaco' in window) {
                const vMode = isDarkMode ? 'vs-dark' : 'vs-light';
                monaco.editor.setTheme(vMode);
            }
        }

        function _toggleMode(isDarkMode) {
            if (isDarkMode) {
                document.getElementById("bs-dark-css").removeAttribute("disabled");
            } else {
                document.getElementById("bs-dark-css").setAttribute("disabled", true);
            }
        }

        function _setMode(isDarkMode) {
            localStorage.setItem('devtools.mode', isDarkMode ?? false);

            if (document.getElementById("bs-dark-css")) {
                if (document.getElementById("bs-dark-css").getAttribute("media")) {
                    setTimeout(function () {
                        document.getElementById("bs-dark-css").removeAttribute("media");
                    }, 10);
                    setTimeout(function () {
                        _toggleMode(isDarkMode);
                    }, 50);
                } else {
                    _toggleMode(isDarkMode);
                }
            }

            if (isDarkMode) {
                document.body.classList.add('o365-dark-mode');
            } else {
                document.body.classList.remove('o365-dark-mode');
            }
            _setMonaco(isDarkMode);
        }

        function setDarkMode(value) {
            darkMode.value = value;
            _setMode(value);
        }

        _setMode(darkMode.value);

        console.warn("o365.vue.components.developermenu.js is depricated, use o365.vue.components.ODeveloperMenu.vue instead.");

        return { o365, darkMode, setDarkMode };
    },
    methods: {
        openDialog: function(){
            if(modal == null){
                var modalEl = createElementFromString(modalTemplate);
                let modalBody = modalEl.querySelector('.modal-body');
                document.body.append(modalEl);
                modal = new bootstrap.Modal(document.getElementById('o365-developer-menu-modal'));
                menuLinks.forEach(group => {
                    let groupElement = createElementFromString(linkGroupTemplate(group.title));
                    group.links.forEach(link => {
                        let linkElement;
                        if (link.apiUrl) {
                            linkElement = createElementFromString(linkTemplate(link.title, 'javascript:void(0)'));
                            linkElement.addEventListener('click', e => {
                                api.request(link.apiUrl, {}).then(response => {
                                }, (reason) => {
                                    console.log(`Api Request Rejected: ${reason}`);
                                });
                                e.preventDefault();
                            });
                        } else {
                            linkElement = createElementFromString(linkTemplate(link.title, link.url));
                            if (link.external) {
                                linkElement.setAttribute('rel', 'noreferrer noopener');
                            }
                            if (link.openInNew) {
                                linkElement.setAttribute('target', '__blank');
                            }
                        }
                        groupElement.append(linkElement);
                    });
                    modalBody.append(groupElement);
                });

                document.getElementById('o365-development-menu-dark-mode-switch').checked = this.darkMode;
                document.getElementById('o365-development-menu-dark-mode-switch').addEventListener('click', e => {
                    let isDarkMode = document.getElementById('o365-development-menu-dark-mode-switch').checked;
                    this.setDarkMode(isDarkMode);
                });
            }
            modal.show();
        }
    },
    template: `
        <a id="o365-development-menu-toggle" @click="openDialog" title="Open developer menu" href="javascript:void(0)"><i class="fa-solid fa-code fa-xs"></i></a>
    `
}

function createElementFromString(html) {
    const placeholder = document.createElement('template');
    placeholder.innerHTML = html;
    return placeholder.content.firstElementChild;
}

const menuLinks = [
    {
        title: 'Apps',
        links: [
            {
                title: 'Article List',
                url: '/appdesigner-articlelist',
            },
            {
                title: 'Edit Current App',
                url: `/appdesigner?ID=${window.location.pathname.substring(window.location.pathname.lastIndexOf('/') + 1)}`,
                external: true,
                openInNew: true,
            },
            {
                title: 'New App',
                url: `/appdesigner?`,
                openInNew: true,
            },
        ],
    },
    {
        title: 'Development',
        links: [
            {
                title: 'Site Setup',
                url: '/sitesetup',
            },
            {
                title: 'DB Manager',
                url: '/db-manager',
                external: true,
            },
            {
                title: 'Code Snippets editor',
                url: '/code-snippets-editor',

            },
            {
                title: 'Code Search',
                url: '/code-search'
            },
        ],
    },
    {
        title: 'Debugging',
        links: [
            {
                title: 'Loaded Assemblies',
                url: '/loaded-assemblies',
            },
            {
                title: 'Cache',
                url: '/api/debug/cache',
            },
            {
                title: 'Log',
                url: '/log',
            },
            {
                title: 'Routes',
                url: '/api/debug/routes'
            }
        ],
    }
];

export { ODeveloperMenu }